export const CountriesEnum = [
	{ locale: 'nl-nl', language: 'nl', value: 'Nederlands (NL)' },
	{ locale: 'nl-be', language: 'nl', value: 'Nederlands (BE)' },
	{ locale: 'en', language: 'en', value: 'English' },
	{ locale: 'es', language: 'es', value: 'Español' },
	{ locale: 'fr', language: 'fr', value: 'Français' },
	{ locale: 'de', language: 'de', value: 'Deutsch' },
	{ locale: 'it', language: 'it', value: 'Italiano' },
	{ locale: 'sv', language: 'sv', value: 'Svenska' },
	{ locale: 'no', language: 'no', value: 'Norsk' },
	{ locale: 'da', language: 'da', value: 'Dansk' },
	// { locale: 'pt', language: 'pt', value: 'Portuguesa' },
];

export const enUs = { locale: 'en-us', language: 'en', value: 'English (US)' };
